import React from "react"
import { privacyPageData } from "components/Data/PrivacyPageData"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import PageContentWrapper from "components/Page/PageContentWrapper"

const PrivacyPolicyPage = () => {
  const page = privacyPageData

  return (
    <Layout pageName="privacy">
      <SeoComponent title="Privacy Policy" description="" />
      {page && page.page_header && (
        <PageHeader fields={page.page_header} pageName="privacy" />
      )}
      <div className="container x-large">
        {page.content && (
          <PageContentWrapper>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </PageContentWrapper>
        )}
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
