const privacyPageData = {
  page_header: {
    heading: `Privacy Notice`,
    style: "jumbo",
  },
  content: `
  <p>If you wish to read this Notice in your native language, you can access Google Translate in your browser, or contact us using the details below.</p>
  <h3>1. Introduction</h3>
  <p>We are a digital data management business called WINR Data Pty Ltd (ABN 73 653 357 450), with our registered office at 100 Walker Street, North Sydney, 2060, New South Wales, Australia. </p>
  
  <p>As a controller and third-party data company, we source personal data from our data partners from online competitions, sales promotions, prize draws, surveys, coupon and sample websites (collectively “Promotional Websites”). We provide multinational FinTech and AdTech companies with data driven identity verification and identity resolution services and solutions.</p>

  <h3>2. What categories of personal data do we process and share?</h3>
  <p>Personal data means any information used to identify directly or indirectly a specific individual. When you provide your data to our data partners who operate the Promotional Websites, different types of your personal data are collected by our data partners and shared with us and third parties.</p>
  
  <p>These types can be summarised into the following categories: </p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12">Identity - This relates to information including full name, date of birth, and gender. </li>
    <li class="col md-12">Contact - This relates to information including residential address, email address, and telephone number.</li>
    <li class="col md-12">Technical - This relates to information including data source, and IP address.</li>
    <li class="col md-12">Website and device usage, and similar information collected by automated means - This relates to information including cookies and similar technologies.</li>
  </ul>
  
  <p>We do not process or share personal data of individuals under the age of 18 years old, special category or sensitive data, or personal data relating to criminal convictions and offences.</p>
  
  <h3>3. What are the purposes for processing and sharing your personal data?</h3>
  
  <p>Your personal data is used by us and our global partners and customers for the following purposes: <br>
  <p style="margin-left: 20px"><strong>Fraud Prevention</strong> - preventing others from misusing your personal information. This may involve identity verification for anti-fraud, anti-money laundering, anti-crime, asset recovery, debt collection and reuniting individuals with unclaimed assets.</p>
  <p style="margin-left: 20px"><strong>Digital Advertising</strong> - providing personalised advertising and content that is relevant to you. This may involve identity resolution for data analysis, matching and linkage.</p>
  <p style="margin-left: 20px"><strong>Legal Obligations</strong> - when we are required to process your data to comply with applicable laws and regulations, and to establish, exercise, or defend against legal claims.</p>

  <p>You can ask us to stop processing or sharing your details at any time at: <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/d1a19402-f516-4c1f-8ce0-9f58e9cecbd9">privacy rights webform</a></p>
  
  <h3>4. What legal basis do we use for processing and sharing your personal data?</h3>
  
  <p>Your personal data must be processed by us and shared to third parties based on one or more valid legal basis. </p>
  
  <p>We rely on the legal basis of legitimate interests for the purposes of fraud prevention, and digital advertising relating to individuals located in the following jurisdictions:</p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12" style="margin-bottom: 10px">European Economic Area ("EEA")</li>
    <li class="col md-12" style="margin-bottom: 10px">United Kingdom ("UK")</li>
    <li class="col md-12" style="margin-bottom: 10px">Switzerland</li>
    <li class="col md-12" style="margin-bottom: 10px">Brazil</li>
    <li class="col md-12" style="margin-bottom: 10px">Indonesia</li>
    <li class="col md-12" style="margin-bottom: 10px">Philippines</li>
    <li class="col md-12" style="margin-bottom: 10px">Singapore</li>
    <li class="col md-12">Thailand</li>
  </ul>

  <p>Where required, we rely on the legal basis of consent for the purposes of fraud prevention, and digital advertising relating to individuals located in the following jurisdictions:</p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12" style="margin-bottom: 10px">Australia</li>
    <li class="col md-12" style="margin-bottom: 10px">New Zealand</li>
    <li class="col md-12" style="margin-bottom: 10px">Canada</li>
    <li class="col md-12" style="margin-bottom: 10px">Colombia</li>
    <li class="col md-12" style="margin-bottom: 10px">Malaysia</li>
    <li class="col md-12" style="margin-bottom: 10px">Mexico</li>
    <li class="col md-12" style="margin-bottom: 10px">South Korea</li>
    <li class="col md-12" style="margin-bottom: 10px">Taiwan</li>
    <li class="col md-12">Vietnam</li>
  </ul>

  <p>In the event of an investigation by a court or regulatory body, we will rely on the legal basis of compliance with legal obligations.</p>
  
  <h4>Legitimate Interest grounds</h4>

  <p>We process and share your personal data with our global partners for the following legitimate commercial interests:</p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12">To identify and prevent fraud, money laundering and crime through verification and validation.</li>
    <li class="col md-12">To assist to recover assets, unclaimed money, and debt collection through verification and validation.</li>
    <li class="col md-12">To develop and enhance our identity resolution services and solutions for the benefit of customers through data analysis, matching and linkage.</li>
    <li class="col md-12">To help make online communication more personalised and relevant. </li>
    <li class="col md-12">To determine the effectiveness of our global partners’ advertising campaigns.</li>
  </ul>

  <p>For all data processing activities, we conduct legitimate interest assessments. These are detailed evaluations to ensure we have balanced the need of processing against your rights and interests for minimal privacy impact.</p>

  <h4>Consent</h4>
  <p>Where required, with your consent we process, use, and disclose your personal data to our global partners for the purposes of fraud prevention, and digital advertising.</p>
  <p>You can withdraw your consent for these purposes at any time at: <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/d1a19402-f516-4c1f-8ce0-9f58e9cecbd9">privacy rights webform</a>

  <h4>Compliance with legal obligations</h4>
  <p>If we are asked to share your personal data with a court or regulatory body for an investigation following a complaint, request, or otherwise, we will do so to comply with legal obligations, and to establish, exercise, or defend against legal claims.</p>
  <p>To the extent that your personal data is not publicly available and subject to U.S. privacy laws, we take steps to ensure that a notice and opt-out choice is provided. See below for more information about our approach to U.S. privacy rules.</p>
  
  <h3>5. Who do we share your personal data with?</h3>
  <p>We may share your personal data with the following categories of recipients:</p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12" style="margin-bottom: 10px">Advertising personalisation and technology</li>
    <li class="col md-12" style="margin-bottom: 10px">Asset repatriation and trace</li>
    <li class="col md-12" style="margin-bottom: 10px">Debt collection</li>
    <li class="col md-12" style="margin-bottom: 10px">Financial products and services</li>
    <li class="col md-12" style="margin-bottom: 10px">Fraud prevention and identity verification services</li>
    <li class="col md-12" style="margin-bottom: 10px">Insurance</li>
    <li class="col md-12" style="margin-bottom: 10px">Lifestyle </li>
    <li class="col md-12" style="margin-bottom: 10px">Media/publishing</li>
    <li class="col md-12" style="margin-bottom: 10px">Professional advisors and consultants</li>
    <li class="col md-12" style="margin-bottom: 10px">Processors acting on our behalf</li>
    <li class="col md-12">Other entities as required under applicable law or in the event of a sale or transfer of our business.</li>
  </ul>

  <h4>Our global partners </h4>
  <p>The below list of our global partners may change from time to time, so please regularly review this Notice for updates. </p>
  <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://ekata.com/privacy-policy/">Ekata Inc</a>&nbsp;(a Mastercard Company)</li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://legal.epsilon.com/global-privacy-policies/">Epsilon Data Management LLC</a></li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.eyeota.com/privacy-center">Eyeota</a>&nbsp;(a Dun & Bradstreet Company)</li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.equifax.com.au/privacy">Equifax Australia Holdings Pty Ltd</a></li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.experian.com.au/privacy-policy">Experian Australia Pty Ltd</a></li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.lseg.com/en/policies/privacy-and-cookie-statement">Global Data Consortium, Inc.</a>&nbsp;(a London Stock Exchange Group Company)</li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.trulioo.com/privacy">Trulioo Information Services Inc.</a></li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://www.thetradedesk.com/us/privacy">The Trade Desk, Inc.</a></li>
    <li class="col md-12"><a target="_blank" rel="nofollow" href="https://zeotap.com/product-privacy-policy/">Zeotap GmbH</a></li>
  </ul>
  
  <h3>6. International data transfers</h3>
  <p>We may transfer your personal data to recipients in countries located outside your home country. When we transfer your personal data cross border, we ensure compliance with applicable laws and privacy practices outlined in this Notice and our policies.</p>
  <p>In cases where the data protection laws in recipient countries differ from those in your home country, we take all necessary steps to ensure your personal data receives adequate protection and safeguards to meet the requirements of applicable laws.</p>

  <p>If we transfer your personal data cross border, we rely on the following safeguards:</p>
  <p style="margin-left: 20px">Adequacy Decisions - adopted by European Commission based on Article 45 of General Data Protection Regulation (“GDPR”) 2016/679 of European Union. For more information, and to access the full list of countries deemed adequate to date, please visit: <a target="_blank" rel="nofollow" href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">EU adequate countries</a>;</p>
  <p style="margin-left: 20px">UK Secretary of State - based on Article 45 of the UK GDPR and Section 17A of the Data Protection Act 2018. For more information, and to access the full list of countries deemed adequate to date, please visit: <a target="_blank" rel="nofollow" href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-transfers-a-guide/">ICO adequate countries</a>; or  </p>
  <p style="margin-left: 20px">The European Commission’s Standard Contractual Clauses (“SCCs”) and the UK Information Commissioner’s Office International Data Transfer Addendum (“IDTA”), as applicable, supplemented by additional security measures as recommended by the European Data Protection Board. The European Commission’s and the UK’s Information Commissioner’s Office have determined that the SCCs and IDTA may provide sufficient safeguards to protect personal data transferred outside the EEA and the UK. For more information, please visit: <a target="_blank" rel="nofollow" href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">standard contractual clauses</a>.</p>
  
  <p>We also perform transfer impact assessments and regularly monitor the circumstances surrounding such transfers to ensure that these maintain, in practice, a level of protection that is essentially equivalent to those guaranteed by the UK and EEA data protection laws.</p>
  
  <p>If you wish to obtain a copy of the safeguards we use to transfer personal data outside of your jurisdiction, you can email us at: <a href="mailto:privacy@winrdata.com">Privacy@winrdata.com</a> or write to us at: </p>
  <p style="margin-left: 20px">
    100 Walker Street<br>
    North Sydney, 2060 <br>
    New South Wales, Australia
  </p>
  
  <h3>7. Security</h3>
  <p>We maintain appropriate technical, organisational, and administrative security measures to protect any personal data we store from accidental, unlawful, or authorised access, alteration, destruction, disclosure, loss, or misuse.</p>
  
  <p>We have secure infrastructure hosted by <a target="_blank" rel="nofollow" href="https://aws.amazon.com/">Amazon Web Services</a>, designed to minimise risk and protect your personal data. Among other practices, we use encryption and hashing standards when we process and share your personal data. </p>
  
  <p>If you wish to obtain a copy of our security measures and certifications, you can email us at: <a href="mailto:privacy@winrdata.com">Privacy@winrdata.com</a> or write to us at: </p>
  <p style="margin-left: 20px">
    100 Walker Street<br>
    North Sydney, 2060 <br>
    New South Wales, Australia
  </p>

  <h3>8. How long do we store your personal data?</h3>  
  <p>We ensure that your personal data is retained only for as long as necessary to fulfil the specific purposes for which it was collected and processed while respecting your privacy rights and regulatory requirements.</p>
  
  <p>We determine the appropriate retention period of personal data for each processing activity based on the following criteria:</p>
    <ul class="content-list row" style="margin-bottom: 10px">
    <li class="col md-12">The category of personal data.</li>
    <li class="col md-12">Whether the personal data is necessary to operate or provide our services and solutions.</li>
    <li class="col md-12">How long we need to retain the personal data to comply with legal obligations.</li>
    <li class="col md-12">Our contractual requirements with global partners.</li>
    <li class="col md-12">Our legitimate commercial interests or legal purposes such as fraud prevention, record-keeping, promoting safety, security and integrity, or enforcing our legal rights. </li>
  </ul>

  <h3>9. How can you exercise your privacy rights?</h3>
  <p>Depending on your country and applicable law, you may have the right to:</p>
   <ul class="content-list row" style="margin-bottom: 10px">
     <li class="col md-12">Opt out of sharing your personal data with third parties.</li>
     <li class="col md-12">Access a copy of your personal data.</li>
     <li class="col md-12">Rectify or update your personal data.</li>
     <li class="col md-12">Restrict or object to processing of your personal data.</li>
     <li class="col md-12">Request deletion of your personal data.</li>
     <li class="col md-12">Receive the personal data you provided to us to transmit it to another company.</li>
     <li class="col md-12">Withdraw any consent provided.</li>
     <li class="col md-12">Review an automated decision where it produces legal effects concerning you or significantly affects you.</li>
  </ul>

  <p>You, or your authorised representative, can submit a request to exercise your privacy rights at: <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/d1a19402-f516-4c1f-8ce0-9f58e9cecbd9">privacy rights webform</a></p>
  
  <p>You, or your authorised representative, can request a copy of our Data Subjects Rights Policy or contact our Data Protection Officer at any time by email to:  <a href="mailto:privacy@winrdata.com">Privacy@winrdata.com</a> or by letter to our headquarters at: </p>
  <p style="margin-left: 20px">
    100 Walker Street<br>
    North Sydney, 2060 <br>
    New South Wales, Australia
  </p>

  <p>We remind you of your right to lodge a complaint with your supervisory authority or regulator should you feel unsatisfied with our treatment of your personal data. </p>
  
  <h3>10. U.S. Privacy laws</h3>
  <p>Certain U.S. States (e.g., California, Colorado, Connecticut, Nevada, Virginia, and Utah) have enacted privacy laws specific to data subjects in those states. For example, the California Consumer Privacy Act (CCPA) provides additional privacy protections for California data subjects, including: a) the right to see what data we have about you, your computer or device (i.e., the right to know), b) the right to delete the data we have about you, your computer or device (i.e., the right to delete), c) the right to correct data we have about you, your computer or device, d) the right to port data about you, your computer or device to a different provider, and e) the right to opt-out of the sale of data about you, your computer or device to certain third parties (i.e., the right to opt-out from sales of your information). We do not discriminate against you if you exercise any of the above rights.</p>
  <p>If you make a privacy rights request as set out in this Notice, we will confirm your request within 10 business days and make a good faith attempt to fulfill your request within 45 days unless applicable law requires us to do otherwise.</p>
  <p>The number of CCPA requests to know that WINR Data received, compiled with whole or part and denied in 2023: 0, 0 and 0.</p>
  <p>The number of CCPA requests to delete that WINR Data received, compiled with whole or part and denied in 2023: 0, 0 and 0.</p>
  <p>The number of CCPA requests to opt-out that WINR Data received (not including requests made via WINR Data’s cookie based opt-out mechanism) compiled with whole or part and denied in 2023: 0, 0 and 0.</p>
  <p>The median number of days within which WINR Data substantively responded to requests to know, requests to delete and requests to opt-out in 2023: 0, 0 and 0.</p>
  <p>Applicable U.S. state privacy law defines personal information broadly and as such, it includes pseudonymous identifiers such as cookie IDs and mobile advertising IDs. Under the CCPA and other applicable U.S. state privacy laws, your request to see the personal information that we have about you may include: (1) specific pieces of personal information that we may have about you; (2) categories of personal information we have collected about you; (3) categories of sources from which the personal information is collected; (4) categories of personal information that we sold or disclosed for a business purpose about you; (5) categories of third parties to whom the personal information was sold or disclosed for a business purpose under the CCPA; and (6) the business or commercial purpose for collecting or selling personal information.</p>
  <p>We may take reasonable steps to verify your request. We will fulfill requests we are able to verify so long as we are not prohibited from doing so by applicable law and/or the information is not essential for us for billing, fraud prevention or security purposes. We will share our reason(s) for denying your request if we are unable to fulfill your request. Some U.S. states (e.g., Colorado and Virginia) provide you with a right to appeal if we deny your request and enable you to complain to the Attorney General in your state. Please send any appeals to <a href="mailto:privacy@winrdata.com">Privacy@winrdata.com</a>.</p>
  <p>You may make an access or deletion request via an authorized agent by having such agent follow the process outlined above. Please note that we will request any authorized agent demonstrate that they have been authorized by you to make a request on your behalf, and we will attempt to verify your request. We require your authorized agent to provide us with contact details such as an email address and phone number so that we may ensure a timely response. </p>
  <p>Regardless of where you reside in the U.S., you may access certain rights with respect to WINR Data at any time at our <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/5fa8f2da-0508-42a6-bbc7-9b031898d10c">U.S. privacy rights webform</a> or calling our toll-free U.S./CA privacy helpline at 1 (888) 369-6450.</p>
  <p>You can also opt-out from the sale of your data via the link here: <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/8bc16c7b-85fb-4f86-9137-18ce126a1b19">Do Not Sell My Personal Information</a></p>
  
  <h3>11. Canadian Privacy laws</h3>
  <p>As required under the Protection of Personal Information and Electronic Documents Act ("PIPEDA"), you have the right to request: a) access to your personal information; b) correction or deletion of your personal information; c) withdrawal of consent for the collection, use, or disclosure of your personal information; d) lodgement of a complaint or inquiry. Alberta, British Columbia, and Quebec have private-sector privacy laws that may apply instead of PIPEDA.</p>
  <p>To respond to your right to access request, Canadian law permits WINR Data to verify your identity. This will require you to supply certain personal information for validation. If you are unable to satisfactorily verify your identity, we will be unable to fulfil your right to access request. In that case, we will endeavour to confirm any personal information we may have about you. We will respond to your privacy rights requests within 30 calendar days of receipt of your request.</p>
  <p>Regardless of where you reside in Canada, you may access certain rights with respect to WINR Data at any time at our <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/c5b81c60-c603-4c83-bdf9-96b1522bc8b3">CA privacy rights webform</a> or calling our toll-free U.S./CA privacy helpline at 1 (888) 369-6450.</p>
  
  <h3>12. Brazilian General Data Protection Law</h3>
  <p>As required by Lei Geral de Proteção de Dados No. 13,709/2018 or General Data Protection Law ("LGPD") you have the right to obtain at any time and upon request: a) confirmation of whether we process your data; b) access to your data; c) correction of incomplete, inaccurate, or outdated data; d) anonymization, blocking (i.e restricting), or deletion of unnecessary or excessive data, or data processed in violation of LGPD; e) port of your data to another service provider; f) deletion of your data; g) opposition to processing h) information about recipients with whom we share your data; i) review of decisions made solely based on automated processing affecting your interests. We do not discriminate against you if you exercise any of the above rights.</p>
  <p>For any information or access requests, we will respond within a period of up to 15 days.  We will respond immediately for any other privacy rights requests. Where this is not possible, we will a) send you a reply to communicate we are not the data processing agent and indicate, where possible, the correct agent; or b) indicate the reasons of fact or law that prevent the immediate adoption of the measure. </p>
  <p>You, or your legal representative, can submit a request to exercise your privacy rights at: <a target="_blank" rel="nofollow" href="https://privacyportal-au.onetrust.com/webform/ad3cafad-4bf2-4d22-951f-57cc1fd05690/3bc5ed1a-13da-4a02-bd9a-09867b94e759">LGPD privacy rights webform</a>.</p>
  
  <h3>13. Our Website Privacy Practices</h3>
  <p>We collect identifiable personal data from this website when you choose to provide it to us. For example, you may choose to send information about yourself in an email, or by completing a form on the website. We use this information only to contact you to respond to your inquiry. Once collected, we will store such information for a reasonable time for record keeping purposes. </p>
  <p>We also automatically collect pseudonymous personal data from visitors to this website, including your IP host address, pages viewed, browser type, Internet Service Provider, domain name, the time/date of your visit to this website, the referring URL and your computer’s operating system. </p>
  <h4>Cookies</h4>
  <p>WINR Data uses cookies or similar technologies to analyse trends and administer the website. Users can control the use of cookies at the individual browser level. Please visit our <a target="_blank" rel="nofollow" href="/cookie-policy">Cookie Policy</a> page for more information. For more general information about cookies, please visit: <a target="_blank" rel="nofollow" href="http://www.allaboutcookies.org/cookies/">http://www.allaboutcookies.org/cookies/</a>.</p>
  
  <h3>14. Changes to this Notice</h3>
  <p>We are constantly improving our website and services, so we may change this Notice from time to time. We will review this Notice on a quarterly basis. You can see when this Notice was last updated by reviewing the date at the bottom of this page. You are responsible for periodically reviewing this Notice for any changes. We will alert you about material changes by placing a notice on our website.</p>
  
  <h3>15. Contact Details</h3>
  <p>VeraSafe has been appointed as WINR Data Pty Ltd’s representative in the UK, and European Union for data protection matters, pursuant to Article 27 of the UK GDPR, and GDPR of European Union. If you are located within the UK or EEA, VeraSafe can be contacted in addition to or instead of our Data Protection Officer only on matters related to the processing of personal data.</p>
  
  <p>If you, or your authorised representative wish to make such an inquiry, please contact VeraSafe using this form: <a target="_blank" rel="nofollow" href="https://verasafe.com/public-resources/contact-data-protection-representative">contact VeraSafe</a> or by telephone at: </p>
  
  <p style="margin-left: 20px">+44 (20) 4532 2003 for UK, or <br>
  +420 228 881 031 for European Union.
  </p>
  
  <p>Alternatively, VeraSafe can be contacted at: </p>
  <p style="margin-left: 20px">
    <strong>VeraSafe United Kingdom Ltd.</strong><br>
    37 Albert Embankment <br>
    London SE1 7TL<br>
    United Kingdom 
  </p>
  <p style="margin-left: 20px">
    <strong>VeraSafe Ireland  Ltd.</strong><br>
    Unit 3D North Point House <br>
    North Point Business Park <br>
    New Mallow Road <br>
    Cork T23AT2P <br>
    Ireland
  </p>
  <p>You, or your authorised representative, may contact our Data Protection Officer at any time by email to:  <a href="mailto:privacy@winrdata.com">Privacy@winrdata.com</a> or by letter to our headquarters at: </p>
  <p style="margin-left: 20px">
    100 Walker Street<br>
    North Sydney, 2060 <br>
    New South Wales, Australia
  </p>
  
  <p style="font-style: italic">This Notice was last updated on 30 January 2024.</p>

  `,
}

export { privacyPageData }
